export default `
  query wishList {
    wishlist {
        items_count
        name
        sharing_code
        updated_at
        items {
          id
          qty
          description
          added_at
          __typename
          product {
            __typename
            uid
            sku
            name
            bestsellers
            new
            sale
            media_gallery {
              url
            }
            small_image {
              url
            }
            url_key
            url_rewrites {
              url
            }
            price_range {
              maximum_price {
                final_price {
                  currency
                  value
                }
                regular_price {
                  currency
                  value
                }
              }
              minimum_price {
                final_price {
                  currency
                  value
                }
                regular_price {
                  currency
                  value
                }
              }
            }
            ... on ConfigurableProduct {
                configurable_options {
                  id
                  uid
                  attribute_uid
                  label
                  values {
                    default_label
                    label
                    uid
                  }
                }
              }
          }
        }
      }
  }
`;
