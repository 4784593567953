







































import { defineComponent, useContext, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  components: {},
  props: {},
  setup() {
    const { app } = useContext();
    const pageRoute = (link: { page:string, anchor?: string }) => {
      const pageroute = app.localeRoute({ name: link.page });
      const pathedRoute = { ...pageroute };
      if (link.anchor) {
        pathedRoute.hash = link.anchor;
      }
      return pathedRoute;
    };

    const errorTabs = ref([
      {
        image: {
          url: 'defaults/error-tab-image-1.png',
        },
        link: {
          title: 'shop now',
          page: 'home',
          anchor: '#categories-section',
        },
      },
      {
        image: {
          url: 'defaults/error-tab-image-2.png',
        },
        link: {
          title: 'm²mag',
          page: 'inside-modern-squared/blog',
          anchor: null,
        },
      },
    ]);
    return {
      pageRoute,
      errorTabs,
    };
  },
});
