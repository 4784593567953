import axios from 'axios';
import { CmsPage } from '@vue-storefront/magento-api';
import { VsfContext } from '~/composables/context';

export const loadSectionsCommand = {
  execute: async (context: VsfContext, params): Promise<CmsPage> => {
    const ENDPOINT = `${params.identifier}-sections`;

    const CMSBaseURL = process.env.VSF_CMS_STRAPI_URL;
    const client = axios.create({
      baseURL: CMSBaseURL,
    });
    const url = `${CMSBaseURL}/${ENDPOINT}`;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const {
      data: { data },
    } = await client.get(url);
    return data;
  },
};
