

































































































































import {
  SfOverlay, SfHeader, SfButton, SfBadge,
} from '@storefront-ui/vue';

import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
  watch,
} from '@nuxtjs/composition-api';
import HeaderNavigation from '~/components/Layouts/Header/Navigation/HeaderNavigation.vue';
import SearchBar from '~/components/Layouts/Header/SearchBar/index.vue';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import { useUiHelpers, useUiState } from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import ModernsquaredLogo from '~/components/ModernsquaredLogo.vue';

export default defineComponent({
  components: {
    HeaderNavigation,
    SfHeader,
    SfOverlay,
    SfButton,
    SfBadge,
    SearchBar,
    ModernsquaredLogo,
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { toggleCartSidebar, toggleWishlistSidebar } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const { categories: categoryList, load: categoriesListLoad } = useCategory();

    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const scrollWatcher = watch(isSearchOpen, (value) => {
      if (document) {
        if (value) {
          document.documentElement.classList.add('html-overflow-hidden');
        } else {
          document.documentElement.classList.remove('html-overflow-hidden');
        }
      }
    });

    const toggleSearchMenu = () => {
      isSearchOpen.value = !isSearchOpen.value;
    };

    const closeSearchMenu = () => {
      if (isSearchOpen.value) {
        toggleSearchMenu();
      }
    };

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(
      () => wishlistStore.wishlist?.items_count || 0,
    );

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const accountIcon = computed(() => (isAuthenticated.value ? 'profile_fill' : 'profile'));
    const categoryTree = ref<CategoryTree[]>([]);

    const handleWishlistClick = async () => {
      const pageName = isAuthenticated.value ? 'customer-my-wishlist' : 'authentication-login';
      await router.push(app.localeRoute({ name: pageName }));
    };

    const handleAccountClick = async () => {
      const pageName = isAuthenticated.value ? 'customer-my-profile' : 'authentication-login';
      await router.push(app.localeRoute({ name: pageName }));
    };

    useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value?.[0]?.children.filter(
        (category) => category.include_in_menu,
      );
    });

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        await loadWishlistItemsCount();
      }
    });

    return {
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      handleWishlistClick,
      wishlistHasProducts,
      wishlistItemsQty,
      toggleSearchMenu,
      isSearchOpen,
      scrollWatcher,
      closeSearchMenu,
    };
  },
});
