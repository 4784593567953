

































import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute,
  defineComponent,
  useFetch,
  ref,
  Ref,
  watch,
} from '@nuxtjs/composition-api';
import { isArray } from 'lodash-es';
import { useUiState, useContent } from '~/composables';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import AppHeader from '~/components/Layouts/Header/index.vue';
import TopBar from '~/components/Layouts/TopBar/index.vue';
import BottomNavigation from '~/components/Layouts/BottomNavigation/index.vue';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    BottomNavigation,
    TopBar,
    Socials: () => import('~/components/Layouts/Socials/index.vue'),
    Newsletter: () => import('~/components/Layouts/Newsletter/index.vue'),
    AppFooter: () => import('~/components/Layouts/Footer/index.vue'),
    CartSidebar: () => import('~/modules/checkout/components/CartSidebar.vue'),
    WishlistSidebar: () => import('~/modules/wishlist/components/WishlistSidebar.vue'),
    LoginModal: () => import('~/modules/customer/components/LoginModal/LoginModal.vue'),
    Notification: () => import('~/components/Notification.vue'),
  },

  setup() {
    const route = useRoute();
    const { loadPageSections, getSection } = useContent();
    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      isScrollThresholded,
    } = useUiState();

    const sections: Ref<Array<any>> = ref([]);
    const topbarSection = ref(null);
    const newsletterSection = ref(null);
    const footerSection = ref(null);
    const supportSection = ref(null);

    const loadSections = () => {
      if (!!sections.value && isArray(sections.value)) {
        topbarSection.value = getSection('topbar-section', sections.value);
        newsletterSection.value = getSection(
          'newsletter-section',
          sections.value,
        );
        footerSection.value = getSection('footer-section', sections.value);
        supportSection.value = getSection('support-section', sections.value);
      }
    };
    useFetch(async () => {
      sections.value = await loadPageSections({ identifier: 'layout' });
      loadSections();
    });

    watch(route, () => {
      document.body.scrollTop = 0;
    });

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
      topbarSection,
      newsletterSection,
      footerSection,
      supportSection,
      isScrollThresholded,
    };
  },
});
