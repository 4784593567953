import { UseContextReturn } from '~/types/core';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';

import productDetail from '~/modules/catalog/category/stores/graphql/productDetail.gql';

export const getProductDetailsCommand = {
  execute: async (context: UseContextReturn, searchParams: GetProductSearchParams, customQuery = { productDetail: 'productDetail' }, customHeaders = {}) => {
    // const { data } = await context.app.$vsf.$magento.api.productDetail(searchParams, customQuery, customHeaders);
    const { data } : { data : any } = await context.app.$vsf.$magento.api.customQuery({ query: productDetail, queryVariables: searchParams });

    return data?.products ?? null;
  },
};
