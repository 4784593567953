import { defineStore } from "pinia";
import type { Customer } from "~/modules/GraphQL/types";

interface CustomerState {
  user: Customer | null;
  isLoggedIn: boolean;
  isSocialsAuthentication: boolean;
}

export const useCustomerStore = defineStore("customer", {
  state: (): CustomerState => ({
    user: null,
    isLoggedIn: false,
    isSocialsAuthentication: false,
  }),
  actions: {
    setIsLoggedIn(isLoggedIn: boolean) {
      this.isLoggedIn = isLoggedIn;
    },
    setIsSocialsAuthentication(isSocialsAuthentication: boolean) {
      this.isSocialsAuthentication = isSocialsAuthentication;
    },
  },
});
