import {
  computed,
  reactive,
  watch,
  ref,
  onMounted,
  onBeforeUnmount,
} from '@nuxtjs/composition-api';
import {
  StateInterface,
  UseUiStateInterface,
} from '~/composables/useUiState/useUiState';

const state = reactive<StateInterface>({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  isSearchMenuOpen: false,
});

const headerPosition = ref(null);
const isScrollThresholded = ref(false);

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const toggleSearchMenu = () => {
    state.isSearchMenuOpen = !state.isSearchMenuOpen;
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  const hideScroll = computed(() => {
    const hiders = [state.isMobileMenuOpen, state.isSearchMenuOpen];
    return hiders.some(Boolean);
  });

  const scrollWatcher = watch(hideScroll, (value) => {
    if (document) {
      if (value) {
        document.documentElement.classList.add('html-overflow-hidden');
      } else {
        document.documentElement.classList.remove('html-overflow-hidden');
      }
    }
  });

  const getHeaderPosition = () => {
    if (document) {
      // eslint-disable-next-line unicorn/prefer-query-selector
      const el = document.getElementById('ms-header');
      return el?.getBoundingClientRect()?.bottom || null;
    }
    return null;
  };

  const getThresholdPass = () => {
    if (document) {
      // eslint-disable-next-line unicorn/prefer-query-selector
      const el = document.documentElement;
      return el.scrollTop > headerPosition.value;
    }
    return false;
  };

  onMounted(() => {
    headerPosition.value = getHeaderPosition();
    window.addEventListener('resize', () => {
      headerPosition.value = getHeaderPosition();
    });
    window.addEventListener('scroll', () => {
      isScrollThresholded.value = getThresholdPass();
    });
    window.addEventListener('touchmove', () => {
      isScrollThresholded.value = getThresholdPass();
      headerPosition.value = getHeaderPosition();
    });
  });

  onBeforeUnmount(() => {
    // eslint-disable-next-line unicorn/no-invalid-remove-event-listener
    window.removeEventListener('resize', () => {});
    window.removeEventListener('touchmove', () => {});
    window.removeEventListener('scroll', () => ({}));
  });

  return {
    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    isSearchMenuOpen: computed(() => state.isSearchMenuOpen),
    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    toggleMobileMenu,
    toggleSearchMenu,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    scrollWatcher,
    headerPosition,
    isScrollThresholded,
  };
}

export default useUiState;
export * from './useUiState';
