import axios from 'axios';

export const loadHelpCenterCommand = {
  execute: async (): Promise<any> => {
    const ENDPOINT = 'help-centers';

    const CMSBaseURL = process.env.VSF_CMS_STRAPI_URL;
    const client = axios.create({
      baseURL: CMSBaseURL,
    });

    const url = `${CMSBaseURL}/${ENDPOINT}`;

    let items: [];
    try {
      const response = await client.get(url);
      items = response.data.data.map((item) => item.attributes);
    } catch {
      items = [];
    }
    return items;
  },
};
