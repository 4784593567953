/* eslint-disable  */

export default {
  "About us": "About us",
  "Account": "Account",
  "Add new address": "Add new address",
  "Add to compare": "Add to compare",
  "Add to Wishlist": "Add to Wishlist",
  "Additional Information": "Additional Information",
  "All Orders": "All Orders",
  "Allow order notifications": "Allow order notifications",
  "Apply": "Apply",
  "Applied Coupon": "Applied Coupon",
  "Attention!": "Attention!",
  "Back to home": "Back to home",
  "Back to homepage": "Back to homepage",
  "Billing": "Billing",
  "Billing address": "Billing address",
  "Brand": "Brand",
  "Cancel": "Cancel",
  "Cart": "Cart",
  "Categories": "Categories",
  "Change": "Change",
  "Change password your account": "If you want to change the password to access your account, enter the following information",
  "Clear all": "Clear all",
  "Color": "Color",
  "Commercial information": "and agree to receive personalized commercial information from Brand name by email",
  "Contact details updated": "Keep your addresses and contact details updated.",
  "Contact us": "Contact us",
  "Continue to billing": "Continue to billing",
  "Continue to payment": "Continue to payment",
  "Continue to shipping": "Continue to shipping",
  "Cookies Policy": "Cookies Policy",
  "Create an account": "Create an account",
  "Customer Reviews": "Customer Reviews",
  "Customer service": "Customer service",
  "Date": "Date",
  "Default Billing Address": "Default Billing Address",
  "Default Shipping Address": "Default Shipping Address",
  "Delete": "Delete",
  "Departments": "Departments",
  "Description": "Description",
  "Details and status orders": "Check the details and status of your orders in the online store. You can also cancel your order or request a return.",
  "Discount": "Discount",
  "Done": "Done",
  "Download": "Download",
  "Download all": "Download all",
  "Edit": "Edit",
  "Email address": "Email address",
  "Empty": "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.",
  "Empty bag": "Empty bag",
  "Enjoy your free account": "Enjoy these perks with your free account!",
  "Enter promo code": "Enter promo code",
  "Feedback": "Your feedback is important to us. Let us know what we could improve.",
  "Feel free to edit": "Feel free to edit any of your details below so your account is always up to date",
  "For Professionals": "For Professionals",
  "Filters": "Filters",
  "Find out more": "Find out more",
  "First Name": "First Name",
  "Forgot Password": "If you can’t remember your password, you can reset it.",
  "Forgot Password Modal Email": "Email you are using to sign in:",
  "forgotPasswordConfirmation": "Thanks! If there is an account registered with the {0} email, you will find message with a password reset link in your inbox.",
  "Forgotten password?": "Forgotten password?",
  "Go back": "Go back",
  "Go back shopping": "Go back shopping",
  "Go back to shop": "Go back to shop",
  "Go to checkout": "Go to checkout",
  "Go to cart": "Go to cart",
  "Guarantee": "Guarantee",
  "Help": "Help",
  "Help & FAQs": "Help & FAQs",
  "hide": "hide",
  "Home": "Home",
  "I agree to": "I agree to",
  "I confirm subscription": "I confirm subscription",
  "I want to create an account": "I want to create an account",
  "Info after order": "You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, check history of transactions, edit subscription to newsletter.",
  "Inside Modern Squared": "Inside Modern Squared",
  "Instruction1": "Take care of me",
  "Instruction2": "Just here for the care instructions?",
  "Instruction3": "Yeah, we thought so",
  "It was not possible to request a new password, please check the entered email address.": "It was not possible to request a new password, please check the entered email address.",
  "Item": "Item",
  "Items": "Items",
  "Kidswear": "Kidswear",
  "Last Name": "Last Name",
  "Let’s start now – we’ll help you": "Let’s start now – we’ll help you.",
  "Log into your account": "Log into your account",
  "Login": "Login",
  "login in to your account": "login in to your account",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.",
  "Looks like you haven’t added any items to the Wishlist.": "Looks like you haven’t added any items to the Wishlist.",
  "Make an order": "Make an order",
  "Manage addresses": "Manage all the addresses you want (work place, home address...) This way you won\"t have to enter the address manually with each order.",
  "Manage billing addresses": "Manage all the billing addresses you want (work place, home address...) This way you won\"t have to enter the billing address manually with each order.",
  "Manage shipping addresses": "Manage all the shipping addresses you want (work place, home address...) This way you won\"t have to enter the shipping address manually with each order.",
  "Match it with": "Match it with",
  "Men fashion": "Men fashion",
  "Menu": "Menu",
  "My billing and shipping address are the same": "My billing and shipping address are the same",
  "My Cart": "My Cart",
  "No account": "Don't have an account yet?",
  "or": "or",
  "or fill the details below": "or fill the details below",
  "Order ID": "Order ID",
  "Order information": "Order information",
  "Order No.": "Order No.",
  "Order summary": "Order summary",
  "Other products you might like": "Other products you might like",
  "Out of stock": "Out of stock",
  "Password": "Password",
  "Password Changed": "Password successfuly changed. You can now go back to homepage and sign in.",
  "Pay for order": "Pay for order",
  "Payment": "Payment",
  "Payment & Delivery": "Payment & Delivery",
  "Payment Method": "Payment Method",
  "Payment Methods": "Payment Methods",
  "Personal details": "Personal details",
  "Please type your current password to change your email address.": "Please type your current password to change your email address.",
  "Price": "Price",
  "Privacy": "Privacy",
  "Privacy Policy": "Privacy Policy",
  "Product": "Product",
  "Product suggestions": "Product suggestions",
  "Products": "Products",
  "Products found": "Products found",
  "Profile": "profile",
  "Purchase terms": "Purchase terms",
  "Quality in the details": "Quality in the details",
  "Quantity": "Quantity",
  "Read all reviews": "Read all reviews",
  "Read and understand": "I have read and understand the",
  "Read reviews": "Read reviews",
  "Register": "Register",
  "Register today": "Register today",
  "Remove": "Remove",
  "Remove Address": "Remove Address",
  "Remove from Wishlist": "Remove from Wishlist",
  "Repeat Password": "Repeat Password",
  "Reset Password": "Reset Password",
  "Review my order": "Review my order",
  "Reviews": "reviews",
  "Same as shipping address": "Same as shipping address",
  "Save changes": "Save changes",
  "Save for later": "Save for later",
  "Save Password": "Save Password",
  "Search": "Search",
  "Search for items": "Search for items",
  "Search results": "Search results",
  "Sections that interest you": "Sections that interest you",
  "See all results": "See all results",
  "See more": "See more",
  "Select payment method": "Select payment method",
  "Select shipping method": "Select shipping method",
  "Send my feedback": "Send my feedback",
  "Set up newsletter": "Set up your newsletter and we will send you information about new products and trends from the sections you selected every week.",
  "Share your look": "Share your look",
  "Shipping": "Shipping",
  "Shipping address": "Shipping address",
  "Shipping details": "Shipping details",
  "Shipping method": "Shipping method",
  "Show":"Show",
  "show more": "show more",
  "Show on page": "Show on page",
  "Sign in": "Sign in",
  "Size guide": "Size guide",
  "Sign Up for Newsletter": "Sign Up for Newsletter",
  "Sort by": "Sort by",
  "Sort: Default": "Default",
  "Sort: Name A-Z": "Name A-Z",
  "Sort: Name Z-A": "Name Z-A",
  "Sort: Price from high to low": "Price from high to low",
  "Sort: Price from low to high": "Price from low to high",
  "Start shopping": "Start shopping",
  "Status": "Status",
  "Subscribe": "Subscribe",
  "Subscribe to newsletter": "Subscribe to newsletter",
  "subscribeToNewsletterModalContent": "After signing up for the newsletter, you will receive special offers and messages from VSF via email. We will not sell or distribute your email to any third party at any time. Please see our {0}.",
  "Subtotal": "Subtotal",
  "Subtotal price": "Subtotal price",
  "Successful placed order": "You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.",
  "Terms and conditions": "Terms and conditions",
  "Thank you": "Thank you",
  "Thank You Inbox": "If the message is not arriving in your inbox, try another email address you might’ve used to register.",
  "Total": "Total",
  "Order Total": "Order Total",
  "Total items": "Total items",
  "Total price": "Total price",
  "Update password": "Update password",
  "Update personal data": "Update personal data",
  "Use your personal data": "At Brand name, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the",
  "User Account": "User Account",
  "View": "View",
  "View details": "View details",
  "Vue Storefront Next": "Vue Storefront Next",
  "Who we are": "Who we are",
  "Wishlist": "Wishlist",
  "Women fashion": "Women fashion",
  "You added {product} to your shopping cart.": "You added {product} to your shopping cart.",
  "You are not authorized, please log in": "You are not authorized, please log in",
  "You can unsubscribe at any time": "You can unsubscribe at any time",
  "You currently have no orders": "You currently have no orders",
  "You haven\’t searched for items yet": "You haven’t searched for items yet.",
  "Your bag is empty": "Your bag is empty",
  "Your current email address is": "Your current email address is",
  "Your email": "Your email",
  "The email field must be a valid email":"The email field must be a valid email",
  'You have submitted no reviews': 'You have submitted no reviews',
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":"The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.",
  "A customer with the same email address already exists in an associated website.": "A customer with the same email address already exists in an associated website.",
  "Invalid email": "Invalid email",
  "SUMMER COLLECTION {year}": "SUMMER COLLECTION {year}",
  "Colorful summer dresses are already in store": "Colorful summer dresses are already in store",
  "Learn more": "Learn more",
  "Dresses": "Dresses",
  "Cocktail & Party": "Cocktail & Party",
  "Linen Dresses": "Linen Dresses",
  "T-Shirts": "T-Shirts",
  "The office life": "The office life",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.",
  "Shop now": "Shop now",
  "The Office Life": "The Office Life",
  "Summer Sandals": "Summer Sandals",
  "Eco Sandals": "Eco Sandals",
  "Subscribe to Newsletters": "Subscribe to Newsletters",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Be aware of upcoming sales and events. Receive gifts and special offers",
  "Fashion to take away": "Fashion to take away",
  "Download our application to your mobile": "Download our application to your mobile",
  "Share Your Look": "Share Your Look",
  "My Account": "My Account",
  "My profile": "My profile",
  "Personal Details": "Personal Details",
  "Addresses details": "Addresses details",
  "My newsletter": "My newsletter",
  "Log out": "Log out",
  "Logout": "logout",
  "My reviews": "My reviews",
  "Order history": "Order history",
  "Orders": "orders",
  "Order details": "Order details",
  "My wishlist": "My wishlist",
  "Password change": "Password change",
  "Personal data": "Personal data",
  "Your e-mail": "Your e-mail",
  "Current Password": "Current Password",
  "You are not authorized, please log in.": "You are not authorized, please log in.",
  "Go To Product": "Go To Product",
  "Change to list view": "Change to list view",
  "Change to grid view": "Change to grid view",
  "Returns":"Returns",
  "My orders": "My orders",
  "Add the address": "Add the address",
  "Set as default shipping": "Set as default shipping",
  "Set as default billing": "Set as default billing",
  "House/Apartment number": "House/Apartment number",
  "Street Name": "Street Name",
  "City": "City",
  "State/Province": "State/Province",
  "Zip-code": "Zip-code",
  "Country": "Country",
  "Phone number": "Phone number",
  "Please select a country first":"Please select a country first",
  "This field is required": "This field is required",
  "The field should have at least 2 characters": "The field should have at least 2 characters",
  "The field should have at least 4 characters": "The field should have at least 4 characters",
  "The field should have at least 8 characters": "The field should have at least 8 characters",
  "New Password": "New Password",
  "New Products": "New Products",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "There are no shipping methods available for this country. We are sorry, please try with different country.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.",
  "We can't find products matching the selection.":"We can't find products matching the selection.",
  'Page not found': 'Page not found',
  'Back to Home page': 'Back to Home page',
  'An error occurred': 'An error occurred',
  "AllProductsFromCategory": "All {categoryName}",
  "Show more": "Show more",
  "Show less": "Show less",
  "Yes": "Yes",
  "No": "No",
  "Apply filters": "Apply filters",
  "The coupon code isn't valid. Verify the code and try again.": "The coupon code isn't valid. Verify the code and try again.",
  "From": "From",
  "Favorites" : "favorites",
  "To": "To",
  "Your customization": "Your customization",
  "Passwords don't match":"Passwords don't match",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)":"The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)",
  "Show all products":"Show all products",
  "Select previously saved address":"Select previously saved address",
  "Enter different address":"Enter different address",
  "You must confirm your account. Please check your email for the confirmation link.": "You must confirm your account. Please check your email for the confirmation link.",
  "Change Store":"Change Store",
  "Choose Currency":"Choose Currency",
  "Add a review":"Add a review",
  "Add to cart":"Add to cart",
  "Title":"Title",
  "Name":"Name",
  "Review":"Review",
  "Add review":"Add review",
  "Are you sure you would like to remove this item from the shopping cart?":"Are you sure you would like to remove this item from the shopping cart?",
  "Your cart is empty":"Your cart is empty",
  "Are you sure?":"Are you sure?",
  "{0} has been successfully removed from your cart":"{0} has been successfully removed from your cart",
  "Amount":"Amount",
  "Thank you for your order!":"Thank you for your order!",
  "Your Purchase":"Your Purchase",
  "Primary contacts for any questions":"Primary contacts for any questions",
  "Your Account":"Your account",
  "What can we improve":"What can we improve",
  "Payment date":"Payment date",
  "The user password was changed successfully updated!":"The user password was changed successfully updated!",
  "The user account data was successfully updated!":"The user account data was successfully updated!",
  "You submitted your review for moderation.": "You submitted your review for moderation.",
  "Starting at": "Starting at",
};
