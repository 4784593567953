export interface SortingModel {
  selected: string,
  options: SortingOption[]
}

export interface SortingOption {
  label: string,
  value: SortingOptionsValuesEnum
}

export enum SortingOptionsValuesEnum {
  DEFAULT = '',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  PRICE_ASC = 'price_ASC',
  PRICE_DESC = 'price_DESC',
  BEST_SELLER = 'bestsellers_ASC',
  NEWS_FROM_DATE = 'news-from-date_DESC',
}

export const sortingOptions: SortingOption[] = [
  {
    label: 'Default',
    value: SortingOptionsValuesEnum.DEFAULT,
  },
  /*   {
    label: 'Sort: Name A-Z',
    value: SortingOptionsValuesEnum.NAME_ASC,
  },
  {
    label: 'Sort: Name Z-A',
    value: SortingOptionsValuesEnum.NAME_DESC,
  }, */
  {
    label: 'Price from low to high',
    value: SortingOptionsValuesEnum.PRICE_ASC,
  },
  {
    label: 'Price from high to low',
    value: SortingOptionsValuesEnum.PRICE_DESC,
  },
  {
    label: 'Best Seller',
    value: SortingOptionsValuesEnum.BEST_SELLER,
  },
  {
    label: 'New',
    value: SortingOptionsValuesEnum.NEWS_FROM_DATE,
  },
];
