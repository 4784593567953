import { UseContextReturn } from "~/types/core";
import type { PlaceOrderOutput } from "~/modules/GraphQL/types";
import { ComposableFunctionArgs } from "~/composables";

export const placeOrderCommand = {
  execute: async (context: UseContextReturn, cartId: string, params?: ComposableFunctionArgs<{}>): Promise<PlaceOrderOutput | null> => {
    /*     const { data } = await context.app.$vsf.$magento.api.placeOrder(
      { cart_id: cartId },
      params?.customQuery ?? null,
      params?.customHeaders,
    ); */

    const customQuery = `
    mutation {
      placeOrder(input: { cart_id: "${cartId}" }) {
        order {
          order_number
          order_id
          client_secret
        }
      }
    }
  `;

    const { data }: any = await context.app.$vsf.$magento.api.customQuery({ query: customQuery });
    return data?.placeOrder ?? null;
  },
};

export const getModuleCommand = {
  execute: async (context: UseContextReturn): Promise<any> => {
    const moduleConfigurationQuery =
      "query { getModuleConfiguration {\n" +
      "        apiKey\n" +
      "        locale\n" +
      "        appInfo\n" +
      "        options {\n" +
      "            betas\n" +
      "            apiVersion\n" +
      "        }\n" +
      "    } }";

    const { data }: any = await context.app.$vsf.$magento.api.customQuery({ query: moduleConfigurationQuery });
    return data?.placeOrder ?? null;
  },
};
