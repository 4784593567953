import type { Plugin } from '@nuxt/types';
import CountryList from 'country-list-with-dial-code-and-flag';
import CountryListFlags from 'country-list-with-dial-code-and-flag/dist/flag-svg';

const plugin: Plugin = (_, inject) => {
  const allCountries = CountryList.getAll().map(({ name, dial_code, code }) => ({
    name,
    dial_code,
    code,
    flag: CountryListFlags[code],
  }));
  inject('countries', { allCountries });
};

export default plugin;
