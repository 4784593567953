









































import { defineComponent } from '@nuxtjs/composition-api';
import SearchBar from './SearchBar.vue';

export default defineComponent({
  components: { SearchBar },
  props: {
    popularSearch: {
      type: Array,
      default: () => [],
    },
  },
  setup() {},
  methods: {
    startSearch() {
      this.$emit('start-search');
    },
  },
});
