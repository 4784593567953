




































import { useRoute, defineComponent } from '@nuxtjs/composition-api';

import { useUiState } from '~/composables';
import { useUser } from '~/modules/customer/composables/useUser';

import ModernsquaredLogo from '~/components/ModernsquaredLogo.vue';

export default defineComponent({
  name: 'HeaderonlyLayout',
  components: {
    ModernsquaredLogo,
    CheckoutSidebar: () => import('~/modules/checkout/components/CheckoutSidebar.vue'),
  },

  setup() {
    const route = useRoute();
    const { isCartSidebarOpen } = useUiState();
    const { isAuthenticated } = useUser();

    return {
      isAuthenticated,
      isCartSidebarOpen,
      route,
    };
  },
/*   head() {
    return { link: [{ rel: 'alternate', hreflang: 'en', href: '/' }] };
  }, */
});
