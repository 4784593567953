/* eslint-disable unicorn/better-regex */
// eslint-disable-next-line unicorn/prefer-regexp-test
export const emailValidator = (email: string) => !!String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

/* eslint-disable unicorn/better-regex */
// eslint-disable-next-line unicorn/prefer-regexp-test, no-useless-escape
export const phoneValidator = (phone: string) => /^[0-9\s]*$/.test(phone);
