import { CustomerAddressInput } from "~/modules/GraphQL/types";

export const transformUserCreateAddressInput = (addressInputParams): CustomerAddressInput => {
  const { apartment, neighborhood, extra, ...address } = addressInputParams.address;

  if (!!address.postcode) {
    address.postcode = postcodeFormatter(address.postcode);
  }

  let street = Array.isArray(address.street) ? [...address.street] : address.street;

  if (apartment) street.push(apartment);

  if (neighborhood) street.push(neighborhood);

  if (extra) {
    if (street.length === 3) {
      street = [street[0], `${street[1]}, ${street[2]}}`];
      street.push(extra);
    } else if (street.length === 2) {
      street.push(extra);
    }
  }

  return {
    ...address,
    street,
  };
};

export const transformUserUpdateAddressInput = (
  addressInputParams
): {
  addressId: number;
  input: CustomerAddressInput;
} => {
  const {
    apartment,
    neighborhood,
    extra,
    id,
    // @ts-ignore
    // eslint-disable-next-line
    extension_attributes,
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    __typename: typenameAddress,
    ...address
  } = addressInputParams.address;

  const {
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    __typename: typenameRegion,
    ...region
  } = address.region;

  if (!!address.postcode) {
    address.postcode = postcodeFormatter(address.postcode);
  }

  let street = Array.isArray(address.street) ? [...address.street] : [address.street];

  if (apartment) street.push(apartment);

  if (neighborhood) street.push(neighborhood);

  if (extra) {
    if (street.length === 3) {
      street = [street[0], `${street[1]}, ${street[2]}}`];
      street.push(extra);
    } else if (street.length === 2) {
      street.push(extra);
    }
  }

  const addressParams: CustomerAddressInput = {
    ...address,
    region,
    street: street.filter(Boolean),
  };

  return {
    addressId: id,
    input: {
      ...addressParams,
    },
  };
};

export const transformUserGetter = (addressInput) => ({
  ...addressInput,
  street: addressInput.street[0],
  apartment: addressInput.street[1],
  neighborhood: addressInput.street[2],
  extra: addressInput.street[3],
});

export const postcodeFormatter = (postcode: string) => {
  // Regular expression for formatted ZIP+4 format
  const formattedRegex = /^\d{5}(?:[-\s]\d{4})?$/;
  // Regular expression for unformatted 9-digit zip code
  const unformattedRegex = /^\d{9}$/;

  if (formattedRegex.test(postcode)) {
    return postcode;
  } else if (unformattedRegex.test(postcode)) {
    const baseZip = postcode.slice(0, 5);
    const extension = postcode.slice(5);
    return `${baseZip}-${extension}`;
  } else {
    return postcode;
  }
};
