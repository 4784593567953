import { MS_COLORS, CURRENCIES_SYMBOLS } from './constants/index';

/* eslint-disable no-param-reassign */
export const keyboardNavigation = (
  event: any,
  index: number,
  table: Array<any>,
  actions: any,
) => {
  const key = event.keyCode;
  if (actions[key] && key !== 9) {
    event.preventDefault();
    actions[key]();
  }
};

export const productColorCode = (color: string) => MS_COLORS[color] || color;
export const currencySymbol = (currency: string) => CURRENCIES_SYMBOLS[currency] || currency;
