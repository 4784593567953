














































































































































import { SfBottomNavigation } from '@storefront-ui/vue';
import {
  defineComponent,
  useRouter,
  useContext,
  computed,
} from '@nuxtjs/composition-api';
import { useUiState } from '~/composables/useUiState';
import { useUser } from '~/modules/customer/composables/useUser';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';

const MobileCategorySidebar = () => import(
  '~/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue'
);

const MobileSearchSidebar = () => import(
  '~/modules/catalog/category/components/sidebar/MobileSearchSidebar/MobileSearchSidebar.vue'
);

export default defineComponent({
  name: 'BottomNavigation',
  components: {
    SfBottomNavigation,
    MobileCategorySidebar,
    MobileSearchSidebar,
  },
  setup() {
    const {
      scrollWatcher,
      toggleCartSidebar,
      toggleMobileMenu,
      toggleSearchMenu,
      isMobileMenuOpen,
      isSearchMenuOpen,
      headerPosition,
    } = useUiState();

    const { isAuthenticated } = useUser();
    const router = useRouter();
    const { app } = useContext();

    const sidebarStyle = computed(() => ({
      top: `${headerPosition.value}px`,
      'max-height': `calc(100% - ${headerPosition.value}px - 77px)`,
    }));

    const handleSearchClick = () => {
      toggleSearchMenu();
      if (isMobileMenuOpen.value) {
        toggleMobileMenu();
      }
    };

    const closeMobileMenu = () => {
      if (isMobileMenuOpen.value) {
        toggleMobileMenu();
      }
    };

    const closeSearchMenu = () => {
      if (isSearchMenuOpen.value) {
        toggleSearchMenu();
      }
    };

    const handleWishlistClick = async () => {
      const pageName = isAuthenticated.value ? 'customer-my-wishlist' : 'authentication-login';
      await router.push(app.localeRoute({ name: pageName }));
    };

    const handleAccountClick = async () => {
      const pageName = isAuthenticated.value ? 'customer-my-profile' : 'authentication-login';
      await router.push(app.localeRoute({ name: pageName }));
    };

    const loadCategoryMenu = async () => {
      const categories = useCategoryStore();
      if (categories.categories === null) {
        await categories.load();
      }
      if (isMobileMenuOpen.value) {
        return;
      }
      toggleMobileMenu();
    };

    return {
      isAuthenticated,
      isMobileMenuOpen,
      isSearchMenuOpen,
      toggleCartSidebar,
      toggleMobileMenu,
      toggleSearchMenu,
      loadCategoryMenu,
      handleWishlistClick,
      handleAccountClick,
      handleSearchClick,
      closeMobileMenu,
      closeSearchMenu,
      scrollWatcher,
      sidebarStyle,
    };
  },
});
