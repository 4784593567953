export const APP_NAME = process.env.APP_NAME || 'Modern Squared';

export const HOME_PAGE_HERO_ICONS = {
  decoration: `<svg viewBox="0 0 47 46" fill="none">
  <path d="M15.4029 44.8756H13.552C13.3834 44.8756 13.2216 44.8086 13.1023 44.6893C12.983 44.57 12.916 44.4082 12.916 44.2395C12.916 44.0709 12.983 43.9091 13.1023 43.7898C13.2216 43.6705 13.3834 43.6035 13.552 43.6035H15.4029C15.5716 43.6035 15.7334 43.6705 15.8526 43.7898C15.9719 43.9091 16.0389 44.0709 16.0389 44.2395C16.0389 44.4082 15.9719 44.57 15.8526 44.6893C15.7334 44.8086 15.5716 44.8756 15.4029 44.8756Z" fill="#5C5C5C" stroke="#5C5C5C"/>
  <path d="M42.975 44.8765H26.1966C26.0279 44.8765 25.8661 44.8095 25.7468 44.6902C25.6275 44.5709 25.5605 44.4092 25.5605 44.2405C25.5605 44.0718 25.6275 43.91 25.7468 43.7907C25.8661 43.6714 26.0279 43.6044 26.1966 43.6044H42.975C43.3124 43.6044 43.6359 43.4704 43.8745 43.2319C44.1131 42.9933 44.2471 42.6698 44.2471 42.3324V18.1633C44.2471 17.8259 44.1131 17.5023 43.8745 17.2638C43.6359 17.0252 43.3124 16.8912 42.975 16.8912H3.54119C3.20382 16.8912 2.88026 17.0252 2.64171 17.2638C2.40315 17.5023 2.26913 17.8259 2.26913 18.1633V42.3324C2.26913 42.6698 2.40315 42.9933 2.64171 43.2319C2.88026 43.4704 3.20382 43.6044 3.54119 43.6044H9.10009C9.26877 43.6044 9.43055 43.6714 9.54983 43.7907C9.66911 43.91 9.73612 44.0718 9.73612 44.2405C9.73612 44.4092 9.66911 44.5709 9.54983 44.6902C9.43055 44.8095 9.26877 44.8765 9.10009 44.8765H3.54119C2.86645 44.8765 2.21934 44.6085 1.74223 44.1313C1.26511 43.6542 0.99707 43.0071 0.99707 42.3324V18.1633C0.99707 17.4885 1.26511 16.8414 1.74223 16.3643C2.21934 15.8872 2.86645 15.6191 3.54119 15.6191H42.975C43.6498 15.6191 44.2969 15.8872 44.774 16.3643C45.2511 16.8414 45.5191 17.4885 45.5191 18.1633V42.3324C45.5191 43.0071 45.2511 43.6542 44.774 44.1313C44.2969 44.6085 43.6498 44.8765 42.975 44.8765Z" fill="#5C5C5C" stroke="#5C5C5C"/>
  <path d="M21.7448 44.8756H19.8558C19.6871 44.8756 19.5253 44.8086 19.406 44.6893C19.2867 44.57 19.2197 44.4082 19.2197 44.2395C19.2197 44.0709 19.2867 43.9091 19.406 43.7898C19.5253 43.6705 19.6871 43.6035 19.8558 43.6035H21.7448C21.9134 43.6035 22.0752 43.6705 22.1945 43.7898C22.3138 43.9091 22.3808 44.0709 22.3808 44.2395C22.3808 44.4082 22.3138 44.57 22.1945 44.6893C22.0752 44.8086 21.9134 44.8756 21.7448 44.8756Z" fill="#5C5C5C" stroke="#5C5C5C"/>
  <path d="M44.8834 38.4102C44.7335 38.4102 44.5885 38.3573 44.4738 38.2608L32.3091 28.0131L23.7405 35.9138C23.6162 36.0263 23.4526 36.0852 23.2851 36.0776C23.1176 36.0701 22.9599 35.9967 22.8463 35.8735C22.7326 35.7503 22.6722 35.5872 22.6781 35.4196C22.6841 35.2521 22.756 35.0937 22.8781 34.9789L31.8588 26.6978C31.9727 26.5927 32.1211 26.5328 32.2761 26.5294C32.431 26.5261 32.5819 26.5793 32.7003 26.6793L45.2937 37.2877C45.3935 37.3717 45.465 37.4844 45.4986 37.6105C45.5322 37.7366 45.5262 37.87 45.4814 37.9925C45.4366 38.1151 45.3552 38.2209 45.2483 38.2956C45.1413 38.3704 45.0139 38.4104 44.8834 38.4102Z" fill="#5C5C5C" stroke="#5C5C5C"/>
  <path d="M28.3649 40.3381C28.2154 40.3381 28.0707 40.2852 27.9566 40.1886L13.5009 28.0124L2.07082 38.8599C1.94836 38.976 1.7848 39.0386 1.61614 39.0341C1.44748 39.0296 1.28753 38.9582 1.17148 38.8358C1.05542 38.7133 0.992768 38.5497 0.9973 38.3811C1.00183 38.2124 1.07318 38.0525 1.19565 37.9364L13.0385 26.6984C13.152 26.5906 13.3012 26.5284 13.4576 26.5237C13.614 26.519 13.7666 26.5722 13.8863 26.673L28.7752 39.2161C28.875 39.3001 28.9465 39.4129 28.9801 39.5389C29.0137 39.665 29.0077 39.7984 28.9629 39.921C28.9181 40.0435 28.8367 40.1494 28.7298 40.2241C28.6228 40.2988 28.4954 40.3382 28.3649 40.3381Z" fill="#5C5C5C" stroke="#5C5C5C"/>
  <path d="M21.3505 27.8122C20.6729 27.8122 20.0106 27.6113 19.4473 27.2349C18.8839 26.8585 18.4448 26.3235 18.1856 25.6975C17.9263 25.0716 17.8584 24.3828 17.9906 23.7183C18.1228 23.0538 18.4491 22.4434 18.9282 21.9643C19.4072 21.4852 20.0176 21.1589 20.6821 21.0268C21.3467 20.8946 22.0354 20.9624 22.6614 21.2217C23.2874 21.481 23.8224 21.9201 24.1988 22.4834C24.5752 23.0467 24.7761 23.7091 24.7761 24.3866C24.7751 25.2948 24.4139 26.1656 23.7717 26.8078C23.1294 27.45 22.2587 27.8112 21.3505 27.8122ZM21.3505 22.2336C20.9245 22.2336 20.5081 22.36 20.1539 22.5966C19.7998 22.8333 19.5237 23.1697 19.3607 23.5632C19.1978 23.9568 19.1551 24.3898 19.2383 24.8076C19.3214 25.2254 19.5266 25.6091 19.8279 25.9103C20.1291 26.2114 20.5129 26.4165 20.9307 26.4995C21.3485 26.5825 21.7815 26.5398 22.175 26.3767C22.5686 26.2136 22.9049 25.9375 23.1414 25.5832C23.378 25.229 23.5042 24.8126 23.5041 24.3866C23.5032 23.8157 23.276 23.2685 22.8723 22.8649C22.4686 22.4613 21.9213 22.2343 21.3505 22.2336Z" fill="#5C5C5C" stroke="#5C5C5C"/>
  <path d="M35.3682 16.8912H11.1482C11.0263 16.8912 10.907 16.8563 10.8045 16.7906C10.7019 16.7248 10.6204 16.631 10.5696 16.5202C10.5188 16.4095 10.5009 16.2865 10.518 16.1659C10.5351 16.0453 10.5865 15.9321 10.666 15.8398L20.4043 4.54138C20.5144 4.4136 20.6708 4.33481 20.8391 4.32235C21.0073 4.30988 21.1736 4.36476 21.3014 4.47491C21.4292 4.58507 21.508 4.74147 21.5204 4.90971C21.5329 5.07795 21.478 5.24426 21.3679 5.37204L12.536 15.6191H34.0153L25.2426 5.03176C25.135 4.90179 25.0835 4.73442 25.0994 4.56647C25.1153 4.39853 25.1972 4.24377 25.3272 4.13623C25.4571 4.02869 25.6245 3.97719 25.7924 3.99306C25.9604 4.00892 26.1152 4.09085 26.2227 4.22082L35.8585 15.8519C35.9348 15.9449 35.9832 16.0577 35.9979 16.1772C36.0127 16.2966 35.9932 16.4177 35.9419 16.5266C35.8905 16.6354 35.8094 16.7274 35.7078 16.7919C35.6063 16.8565 35.4885 16.8909 35.3682 16.8912Z" fill="#5C5C5C" stroke="#5C5C5C"/>
  <path d="M23.2583 7.22358C22.6293 7.22358 22.0145 7.03707 21.4915 6.68763C20.9685 6.33819 20.5609 5.84152 20.3202 5.26042C20.0795 4.67932 20.0165 4.0399 20.1392 3.42301C20.2619 2.80613 20.5648 2.23948 21.0096 1.79473C21.4543 1.34997 22.021 1.0471 22.6379 0.924388C23.2547 0.801682 23.8942 0.864659 24.4753 1.10536C25.0564 1.34606 25.553 1.75366 25.9025 2.27664C26.2519 2.79961 26.4384 3.41446 26.4384 4.04343C26.4374 4.88655 26.102 5.69484 25.5059 6.29102C24.9097 6.88719 24.1014 7.22257 23.2583 7.22358ZM23.2583 2.13534C22.8809 2.13534 22.512 2.24725 22.1982 2.45691C21.8844 2.66658 21.6398 2.96458 21.4954 3.31324C21.351 3.66189 21.3132 4.04555 21.3868 4.41568C21.4605 4.78581 21.6422 5.1258 21.9091 5.39265C22.1759 5.6595 22.5159 5.84123 22.886 5.91486C23.2562 5.98848 23.6398 5.95069 23.9885 5.80627C24.3371 5.66186 24.6351 5.41729 24.8448 5.10351C25.0545 4.78972 25.1664 4.42081 25.1664 4.04343C25.1664 3.53737 24.9653 3.05204 24.6075 2.69421C24.2497 2.33637 23.7643 2.13534 23.2583 2.13534Z" fill="#5C5C5C" stroke="#5C5C5C"/>
  </svg>`,
  sustainability: `<svg viewBox="0 0 48 58" fill="none">
  <path d="M16.0862 22.6223C12.3824 28.9135 15.4223 33.9483 16.4373 35.3405C15.196 37.6241 14.2395 40.1347 13.9053 42.7728C13.8292 43.3736 14.2551 43.9224 14.8559 43.9985C14.9028 44.005 14.9489 44.0076 14.9951 44.0076C15.5393 44.0076 16.0121 43.6025 16.0823 43.0485C17.2677 33.685 27.606 25.5946 27.71 25.514C28.1892 25.1433 28.2777 24.4541 27.9077 23.9748C27.537 23.4956 26.8484 23.4059 26.3692 23.7765C26.0681 24.0087 21.2648 27.7651 17.68 33.2617C16.7234 31.6211 15.4028 28.1085 17.9771 23.7356C19.58 21.011 22.0763 20.578 24.9673 20.076C27.089 19.7086 29.4403 19.3002 31.5536 17.8729C32.2318 21.1183 33.2709 28.3848 30.1601 32.6621C28.6086 34.7956 26.1449 35.9309 22.8371 36.0356C22.2317 36.0545 21.7557 36.5603 21.7752 37.1657C21.7941 37.76 22.2811 38.2289 22.8715 38.2289H22.9066C26.9297 38.1021 29.9676 36.6618 31.9385 33.949C36.6411 27.4738 33.4074 16.0926 33.2676 15.6107C33.1591 15.2401 32.8638 14.952 32.49 14.8551C32.1141 14.7589 31.7175 14.8649 31.4424 15.138C29.4956 17.064 27.1144 17.4776 24.5921 17.9146C21.452 18.4588 18.2047 19.0219 16.0862 22.6223Z" fill="#5C5C5C" stroke="#5C5C5C" stroke-width="0.55"/>
  <path d="M15.0265 50.4136C15.1663 50.4734 15.3126 50.5014 15.4557 50.5014C15.8822 50.5014 16.288 50.2511 16.4661 49.8336C16.7035 49.2763 16.444 48.632 15.8861 48.3946C7.23916 44.7103 2.10678 35.9509 3.11401 26.5958C4.26624 15.896 13.226 7.89927 23.7118 7.65868L21.0023 9.8409C20.5302 10.2213 20.4561 10.9119 20.8358 11.3839C21.0523 11.6531 21.3703 11.7929 21.6909 11.7929C21.9328 11.7929 22.176 11.7136 22.3788 11.5504L27.3669 7.53318C27.5938 7.35046 27.7382 7.08581 27.7694 6.7958C27.8006 6.50579 27.7154 6.21643 27.5333 5.99015L23.5155 1.00277C23.1357 0.530687 22.4452 0.456559 21.9724 0.836303C21.5004 1.2167 21.4262 1.90726 21.8066 2.37934L24.2821 5.4524C12.4463 5.40688 2.22642 14.3432 0.932433 26.3604C-0.179487 36.6824 5.4848 46.3483 15.0265 50.4136Z" fill="#5C5C5C" stroke="#5C5C5C" stroke-width="0.55"/>
  <path d="M32.3345 9.25752C40.9782 12.8319 46.3148 21.8313 45.3127 31.1435C44.1592 41.842 35.1956 49.8328 24.7142 50.078L27.4232 47.8971C27.8952 47.516 27.9694 46.8268 27.5896 46.354C27.2092 45.8813 26.5193 45.8078 26.0466 46.1876L21.0579 50.2048C20.831 50.3875 20.686 50.6522 20.6548 50.9415C20.6235 51.2309 20.7087 51.5209 20.8915 51.7478L24.91 56.7352C25.1271 57.0044 25.4445 57.1442 25.7644 57.1442C26.0063 57.1442 26.2495 57.0642 26.453 56.9017C26.9251 56.5206 26.9992 55.8301 26.6188 55.3586L24.1414 52.2836C24.1706 52.2836 24.1999 52.2862 24.2292 52.2862C36.0246 52.2849 46.2023 43.3655 47.4943 31.3782C48.5997 21.1043 42.7111 11.1738 33.1733 7.2294C32.6141 6.99922 31.971 7.26452 31.7401 7.82438C31.5087 8.38424 31.7746 9.02538 32.3345 9.25752Z" fill="#5C5C5C" stroke="#5C5C5C" stroke-width="0.55"/>
  </svg>`,
  pricing: `<svg viewBox="0 0 46 50" fill="none">
  <path d="M22.7535 27.3687C26.6967 27.3687 29.9044 24.0045 29.9044 19.8689C29.9044 15.7333 26.6967 12.3691 22.7535 12.3691C18.8106 12.3691 15.6025 15.7333 15.6025 19.8689C15.6025 24.0045 18.8106 27.3687 22.7535 27.3687ZM22.7535 14.0358C25.8199 14.0358 28.3153 16.6529 28.3153 19.8689C28.3153 23.0849 25.8199 25.7021 22.7535 25.7021C19.6866 25.7021 17.1916 23.0849 17.1916 19.8689C17.1916 16.6529 19.6866 14.0358 22.7535 14.0358Z" fill="#5C5C5C" stroke="#5C5C5C" stroke-width="0.5"/>
  <path d="M30.0087 36.6108L30.0089 36.6106L42.3218 24.5302C42.6429 24.1913 42.8325 23.7145 42.8325 23.203C42.8325 22.6916 42.6428 22.2133 42.3007 21.8537C41.6214 21.1431 40.4348 21.1432 39.7556 21.8538L30.0087 36.6108ZM30.0087 36.6108C29.8067 36.8093 29.6945 37.0843 29.6945 37.3692V43.8257H15.0181V37.3692C15.0181 37.0952 14.9144 36.8314 14.7273 36.6351L3.20645 24.5523C3.20643 24.5522 3.20641 24.5522 3.20638 24.5522C2.86394 24.1926 2.6743 23.7143 2.6743 23.203C2.6743 22.6917 2.86395 22.2134 3.2064 21.8538C3.88487 21.1431 5.07326 21.1431 5.75169 21.8539L15.6835 32.2702C15.8709 32.4668 16.1275 32.5794 16.3972 32.5794H21.7491V40.7024C21.7491 41.2696 22.1896 41.7458 22.7536 41.7458C23.3176 41.7458 23.7582 41.2696 23.7582 40.7024V32.5794H29.11C29.3802 32.5794 29.6363 32.4667 29.8238 32.2702L39.7555 21.854L30.0087 36.6108ZM43.7234 26.0253L43.7235 26.0254L43.7283 26.0204C45.2109 24.4655 45.2109 21.9405 43.7283 20.3856C43.0093 19.6315 42.0493 19.2138 41.0282 19.2138C40.0071 19.2138 39.0471 19.6315 38.3281 20.3856L28.6911 30.4928H16.8161L7.17912 20.3856C6.46009 19.6315 5.50052 19.2138 4.479 19.2138C3.45748 19.2138 2.4979 19.6315 1.77887 20.3856C0.296314 21.9405 0.296314 24.4655 1.77887 26.0204L13.009 37.7983V48.2022C13.009 48.7693 13.4496 49.2455 14.0136 49.2455H30.6991C31.2636 49.2455 31.7036 48.7692 31.7036 48.2022V37.8187L43.7234 26.0253ZM15.0181 47.1589V45.9123H29.6945V47.1589H15.0181Z" fill="#5C5C5C" stroke="#5C5C5C" stroke-width="0.42"/>
  <path d="M35.081 16.4315C35.2028 16.5031 35.3355 16.5365 35.4659 16.5365C35.7452 16.5365 36.016 16.3819 36.1611 16.1076L40.1338 8.60786C40.3472 8.20585 40.2091 7.69887 39.825 7.47508C39.4425 7.24967 38.9575 7.39615 38.7449 7.79896L34.7722 15.2987C34.5588 15.7007 34.6969 16.2077 35.081 16.4315Z" fill="#5C5C5C" stroke="#5C5C5C" stroke-width="0.5"/>
  <path d="M9.34546 16.1076C9.49056 16.3819 9.76136 16.5365 10.0407 16.5365C10.171 16.5365 10.3037 16.5031 10.4259 16.4315C10.8092 16.2077 10.9477 15.7007 10.7344 15.2987L6.76163 7.79896C6.54902 7.39615 6.06484 7.24967 5.68115 7.47508C5.29784 7.69887 5.15934 8.20585 5.37272 8.60786L9.34546 16.1076Z" fill="#5C5C5C" stroke="#5C5C5C" stroke-width="0.5"/>
  <path d="M4.47897 2.36974C4.47897 3.29012 3.76745 4.03635 2.88988 4.03635C2.01192 4.03635 1.30078 3.29012 1.30078 2.36974C1.30078 1.44936 2.01192 0.703125 2.88988 0.703125C3.76745 0.703125 4.47897 1.44936 4.47897 2.36974Z" fill="#5C5C5C" stroke="#5C5C5C" stroke-width="0.5"/>
  <path d="M44.2055 2.36974C44.2055 3.29012 43.494 4.03635 42.6164 4.03635C41.7385 4.03635 41.0273 3.29012 41.0273 2.36974C41.0273 1.44936 41.7385 0.703125 42.6164 0.703125C43.494 0.703125 44.2055 1.44936 44.2055 2.36974Z" fill="#5C5C5C" stroke="#5C5C5C" stroke-width="0.5"/>
  <path d="M23.5485 8.20289C23.9876 8.20289 24.343 7.83018 24.343 7.36958V1.53643C24.343 1.07583 23.9876 0.703125 23.5485 0.703125C23.1097 0.703125 22.7539 1.07583 22.7539 1.53643V7.36958C22.7539 7.83018 23.1097 8.20289 23.5485 8.20289Z" fill="#5C5C5C" stroke="#5C5C5C" stroke-width="0.5"/>
  </svg>`,
};

export const HOME_PAGE_HERO_DEFAULT_ICON = HOME_PAGE_HERO_ICONS.decoration;

export const MS_COLORS = {
  Grey: '#E0DFDC',
  Red: '#FF1313',
  Orange: '#F1673B',
  Yellow: '#FFE351',
  Green: '#7EFF51',
  Cyan: '#51FFAB',
  Blue: '#5F51FF',
  Purple: '#BD51FF',
  Pink: '#FF51B9',
  Brown: '#964B00',
};

export const CURRENCIES_SYMBOLS = {
  EUR: '€',
  GBP: '£',
  USD: '$',
};
